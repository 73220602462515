<template>
  <div class="form-wrapper">
    <div class="main-form">
      <card-basic>
        <div class="content-preview-section">
          <h3>콘텐츠 기본정보</h3>
          <a v-if="liveLink" :href="liveLink" target="_blank">VOD 미리보기</a>
          <a v-if="videoLink" :href="videoLink" target="_blank"
            >컨텐츠 원본 보기</a
          >
        </div>

        <div class="content-type-area">
          <label class="sub-text-s2"
            >콘텐츠 타입<span class="text-purple-50">*</span></label
          >
          <div class="input-radio-area">
            <input-radio
              v-model.number="state.contentForm.type"
              name="article-type"
              label="Article"
              :value="1"
              for-value="article-type"
            ></input-radio>
            <input-radio
              v-model.number="state.contentForm.type"
              name="video-type"
              label="Video"
              :value="3"
              for-value="video-type"
            ></input-radio>
            <input-radio
              v-model.number="state.contentForm.type"
              name="link-type"
              label="Link"
              :value="5"
              for-value="link-type"
            ></input-radio>
            <!-- note eduk 교육있고 테마 콘텐츠로 사용할려고 만들었던 타입 현재 사용 안함.         -->
            <input-radio
              v-if="false"
              v-model.number="state.contentForm.type"
              name="eduK-type"
              label="eduK"
              :value="300"
              for-value="eduK-type"
            ></input-radio>
            <input-radio
              :model-value="state.contentForm.type"
              name="drm-type"
              label="Video Drm"
              :value="7"
              for-value="drm-type"
              @update:modelValue="
                (numberValue) => actions.updateContentDrmType(numberValue)
              "
            ></input-radio>
            <input-radio
              :model-value="state.contentForm.type"
              name="digital-goods-type"
              label="digital-goods"
              :value="9"
              for-value="digital-goods-type"
              @update:modelValue="
                (numberValue) => actions.updateContentDigitalGoods(numberValue)
              "
            ></input-radio>
          </div>
        </div>

        <input-basic
          class="input-area"
          :default-value="state.contentForm.title"
          label="제목"
          placeholder="콘텐츠 제목을 입력해 주세요."
          :required="true"
          @updateData="(value) => actions.updateTitle(value)"
        ></input-basic>

        <div class="description-area">
          <input-text-box-new
            label="콘텐츠 내용 요약"
            placeholder="콘텐츠 내용을 요약해보세요."
            :rows="10"
            :height-resize="false"
            :model-value="state.contentForm.description"
            @update:modelValue="
              (value) => {
                actions.updateDescription(value);
              }
            "
          ></input-text-box-new>
          <quill-editor
            label="본문 내용 요약"
            placeholder="본문 내용을 입력하세요."
            :value="state.contentForm.text"
            :editor-container-height="500"
            :is-align-toolbar="true"
            :limit-copypast-text-foramtting="false"
            @updateValue="(value) => actions.updateText(value)"
          >
          </quill-editor>
        </div>

        <div v-if="content" class="attachment-add">
          <div>
            <h5>첨부파일 추가</h5>
            <label
              v-if="state.contentForm.type === 9"
              class="text-gray-second sub-text-s3"
            >
              디지털 굿즈는 {{ acceptFiles }} 포맷을 지원합니다.
            </label>
          </div>
          <button-basic
            class="attachment-btn"
            bg-color="#f5f4f8"
            :disabled="state.addAttachmentLoading"
            @action="actions.openAttachmentSelector()"
          >
            <template #icon>
              <plus-icon stroke-size="normal"></plus-icon>
            </template>
          </button-basic>

          <input
            ref="attachmentFileInput"
            type="file"
            :accept="acceptFiles"
            hidden
            @change="actions.addAttachment($event)"
          />
        </div>
        <div>
          <attachment-file-link
            v-for="attachment in state.attachments"
            :key="attachment.resourceId"
            :attachment="attachment"
            :direct-delete="false"
            @popAttachment="
              (resourceId) => {
                actions.deleteAttachment(resourceId);
              }
            "
          ></attachment-file-link>
        </div>
      </card-basic>
    </div>

    <div class="sub-form">
      <card-basic>
        <ul>
          <li>
            <input-image
              label="이미지 변경"
              delete-text="삭제"
              :featured-image="state.prevFeaturedImage"
              @updateData="
                (previewImage) => {
                  actions.updatePreviewImage(previewImage);
                }
              "
              @deleteImage="actions.deleteContentFeaturedImage()"
            ></input-image>
          </li>
          <li v-if="isNcloudThumbnails" class="thumbnail">
            <label class="sub-text-s2">썸네일 선택</label>
            <ul>
              <li v-for="(item, index) in thumbnails" :key="index">
                <div class="overlay"></div>
                <div class="btn-wrapper">
                  <button-basic
                    shape="round"
                    bg-color="#e6eaef"
                    padding="12px 12px"
                    @action="
                      () => {
                        actions.selectThumbnail(item);
                      }
                    "
                  >
                    <template #icon>
                      <edit-icon
                        width="16"
                        height="16"
                        fill-color="#0D0D10"
                      ></edit-icon>
                    </template>
                  </button-basic>
                </div>
                <img :src="item" alt="" />
              </li>
            </ul>
          </li>
          <li>
            <input-select-small
              v-model="state.contentForm.channelCode"
              label="채널"
              :list="channelList"
            ></input-select-small>
          </li>
          <li>
            <div>
              <div>
                <label class="sub-text-s2">공개일시</label>
                <span class="text-purple-50">*</span>
              </div>
              <input-date-picker
                :date="state.contentForm.publishedAt"
                :is-deletable="true"
                format="YYYY-MM-DD HH:mm"
                @updateDate="(date) => actions.updatePublishedAt(date)"
              ></input-date-picker>
            </div>
          </li>
          <li>
            <input-switch
              v-model="state.contentForm.isPaid"
              text-size="sub-text-s2"
              label="유료 콘텐츠"
            ></input-switch>
          </li>
          <li>
            <input-switch
              v-model="state.contentForm.isUserOnly"
              :disabled="state.contentForm.type === 7"
              text-size="sub-text-s2"
              label="회원 전용 콘텐츠"
            ></input-switch>
          </li>
          <li>
            <button-basic
              text="변경사항 저장"
              :disabled="!state.activeBtn || state.saveLoading"
              @action="actions.onBtnSaveContent()"
            ></button-basic>
          </li>
          <li>
            <button-basic
              text="삭제"
              color="#FF522D"
              bg-color="#ffffff"
              border-color="#FF522D"
              :border="true"
              :disabled="state.deleteLoading"
              @action="actions.showDeleteContentModal()"
            ></button-basic>
          </li>
        </ul>
      </card-basic>
      <card-basic
        v-if="state.contentForm.type === 3 || state.contentForm.type === 7"
        class="upload-btns"
      >
        <ul>
          <li v-show="state.contentForm.type === 3">
            <button-basic
              text="동영상 임베드"
              @action="
                () => {
                  actions.openContentSettingModal('embed');
                }
              "
            ></button-basic>
          </li>
          <li v-show="state.contentForm.type === 7">
            <button-basic
              text="동영상 업로드"
              @action="
                () => {
                  actions.openContentSettingModal('upload');
                }
              "
            ></button-basic>
          </li>
        </ul>
      </card-basic>
      <card-basic
        v-if="!state.isPrd && state.contentForm.type === 7"
        class="viewing-btns"
      >
        <h4>유저 시청환경 설정</h4>
        <ul>
          <li
            v-for="(item, index) in viewingMode"
            :key="`${index}-${item.value}`"
          >
            <input-switch
              v-model="state.contentForm[`${item.value}`]"
              text-size="sub-text-s2"
              :label="item.label"
            ></input-switch>
          </li>
        </ul>
      </card-basic>
    </div>

    <teleport :disabled="true">
      <warning-modal
        v-if="state.showPageOutWarning"
        warning-title="콘텐츠 작성을 취소할까요?"
        warning-text="이 페이지를 나가면 지금까지의 변경사항이 저장되지 않습니다."
        confirm-text="나가기"
        cancel-text="계속쓰기"
        @confirm="actions.pageOut()"
        @hideModal="actions.pageOutCancel()"
      ></warning-modal>

      <warning-modal
        v-if="state.showDeleteContentModal"
        warning-title="콘텐츠를 삭제하시겠습니까?"
        warning-text="삭제 후 복구는 불가능합니다."
        confirm-text="삭제"
        cancel-text="취소"
        @confirm="actions.deleteContent()"
        @hideModal="actions.closeDeleteContentModal()"
      ></warning-modal>

      <warning-modal
        v-if="state.showEssentialCheckModal"
        :is-cancel-btn="false"
        :is-close-btn="false"
        warning-title="Drm 설정 안내"
        :warning-text="isVideoDrmSetting.message"
        confirm-text="확인"
        @confirm="state.showEssentialCheckModal = false"
        @hideModal="actions.closeEssentialCheckModal()"
      ></warning-modal>

      <contents-setting-modal
        v-if="state.showContentSettingModal"
        :type="state.uploadedType"
        :content="state.contentForm"
        :resource-id="state.resourceId"
        @hide-modal="actions.closeContentSettingModal"
        @update-form="(data) => actions.updateContentForm(data)"
      ></contents-setting-modal>
    </teleport>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, getCurrentInstance, onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import moment from "moment-timezone";
import swal from "@/helper/swal";
import { onBeforeRouteLeave } from "vue-router";
import WarningModal from "@/components/console/modals/WarningModal.vue";
import InputSwitch from "@/components/console/inputs/InputSwitch.vue";
import InputSelectSmall from "../inputs/InputSelectSmall";
import AttachmentFileLink from "../files/AttachmentFileLink";
import InputImage from "../inputs/InputImage";
import InputBasic from "@/components/console/inputs/InputBasic";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import PlusIcon from "@/components/console/icons/PlusIcon.vue";
import InputDatePicker from "@/components/console/inputs/InputDatePicker.vue";
import CardBasic from "@/components/console/cards/CardBasic.vue";
import InputRadio from "@/components/console/inputs/InputRadio.vue";
import InputTextBoxNew from "../inputs/InputTextBoxNew";
import QuillEditor from "@/components/console/editors/QuillEditor.vue";
import EditIcon from "@/components/console/icons/EditIcon.vue";
import ContentsSettingModal from "@/pages/console/ContentEdit/ContentsSettingModal/ContentsSettingModal.vue";
import { cloneDeep } from "lodash";

export default {
  name: "ContentForm",
  components: {
    EditIcon,
    QuillEditor,
    InputRadio,
    CardBasic,
    InputDatePicker,
    PlusIcon,
    ButtonBasic,
    InputBasic,
    InputImage,
    AttachmentFileLink,
    InputSelectSmall,
    InputSwitch,
    WarningModal,
    InputTextBoxNew,
    ContentsSettingModal,
  },
  props: {
    content: {
      type: Object,
      required: false,
    },
    resourceId: {
      type: String,
    },
    testText: {
      type: String,
    },
  },
  emits: ["cancelContent"],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const { proxy } = getCurrentInstance();

    const channelList = [
      {
        value: "",
        text: "채널을 선택해주세요.",
      },
      {
        value: "youtube",
        text: "youtube",
      },
      {
        value: "brunch",
        text: "brunch",
      },
      {
        value: "vimeo",
        text: "vimeo",
      },
      {
        value: "ncloud",
        text: "ncloud",
      },
    ];

    const attachmentFileInput = ref();

    const state = reactive({
      contentForm: {
        type: props.content.type,
        channelCode: props.content ? props.content.channelCode : "",
        identifier: props.content ? props.content.identifier : "",
        etag: props.content ? props.content.etag : "",
        url: props.content ? props.content.url : "",
        urlDash: props.content ? props.content.urlDash : "",
        urlDashSubtitles: props.content ? props.content.urlDashSubtitles : "",
        urlHls: props.content ? props.content.urlHls : "",
        urlHlsSubtitles: props.content ? props.content.urlHlsSubtitles : "",
        drmCid: props.content ? props.content.drmCid : "",
        title: props.content ? props.content.title : "",
        description: props.content ? props.content.description : "",
        text: props.content ? props.content.text : "",
        featuredImage: props.content.featuredImage,
        publishedAt: props.content?.publishedAt
          ? props.content.publishedAt
          : "",
        isPaid: props.content.isPaid,
        isUserOnly: props.content.isUserOnly,
        attachments: [], //attachment의 id 값이 모여 았는 곳
        captions: props.content.captions,
        isIosMoweb: props.content ? props.content.isIosMoweb : true,
        isIosIframe: props.content ? props.content.isIosIframe : false,
        isAosMoweb: props.content ? props.content.isAosMoweb : true,
        isAosIframe: props.content ? props.content.isAosIframe : false,
      },
      attachments: computed(() => {
        return props.content.attachments;
      }),
      showEssentialCheckModal: false,
      isChange: computed(() => {
        let changeArr = [];
        Object.keys(state.contentForm).forEach((key) => {
          changeArr.push(
            state.contentForm[key] === store.getters["contents/content"][key]
          );
        });
        return changeArr.includes(false);
      }),
      addAttachmentLoading: false,
      saveLoading: false,
      activeBtn: computed(() => {
        return (
          state.contentForm.title?.length > 0 &&
          !!state.contentForm.publishedAt &&
          state.contentForm.type !== 0
        );
      }),
      deleteLoading: false,
      prevFeaturedImage: props.content
        ? props.content.featuredImage
        : proxy.$const.blankImage,
      pageOutApprove: false,
      showPageOutWarning: false,
      showDeleteContentModal: false,
      nextRouteName: null,
      deletedAttachments: [], // attachment의 id 값이 모여 았는 곳
      triggerRouteLeave: false,
      resourceId: "",
      width: 0,
      showContentSettingModal: false,
      uploadedType: "",
      isEdit: false,
      isPrd: process.env.NODE_ENV === "production",
    });

    const acceptFiles = computed(() => {
      if (state.contentForm.type === 9) {
        return ".mp4,.gif,.webp,.png,.jpeg";
      }
      return ".jpg,.pdf,.doc,.png,.gif,.jpeg,.webp";
    });

    const viewingMode = [
      {
        value: "isIosMoweb",
        label: "[iOS] 모바일웹 시청 허용",
      },
      {
        value: "isIosIframe",
        label: "[iOS] 앱에서 웹뷰어(iFrame) 사용 여부",
      },
      {
        value: "isAosMoweb",
        label: "[AOS] 모바일웹 시청 허용",
      },
      {
        value: "isAosIframe",
        label: "[AOS] 앱에서 웹뷰어(iFrame) 사용 여부",
      },
    ];

    const isNcloudThumbnails = computed(() => {
      return props.content?.urlHls && props.content?.channelCode === "ncloud";
    });

    const isNonEmptyString = (str) => typeof str === "string" && str.length > 0;

    const isVideoDrmSetting = computed(() => {
      let isVideoDrmUrl = isNonEmptyString(state.contentForm.urlHls);

      let isVideoDrmCondition =
        state.contentForm.type === 7 &&
        state.contentForm.channelCode === "ncloud";

      let result = {
        success: true,
        message: "",
      };

      if (isVideoDrmUrl && !isVideoDrmCondition) {
        result.success = false;
        result.message =
          "Drm 설정시 콘텐츠의 타입은 Video Drm, 채널은 ncloud로 설정되어야 합니다.";
        return result;
      } else {
        return result;
      }
    });

    const thumbnails = computed(() => {
      if (state.resourceId) {
        let arr = [];
        let env = process.env.NODE_ENV === "development" ? "dev" : "prd";
        for (let i = 1; i < 11; i++) {
          let imageUrl = `https://xpx2fylp2310.edge.naverncp.com/vodsThumbs/${env}_content_${
            state.resourceId
          }/${env}_content_${state.resourceId}_${i < 10 ? "0" + i : i}.jpg`;
          arr[i - 1] = imageUrl;
        }
        return arr;
      } else {
        return [];
      }
    });

    const liveLink = computed(() => {
      const link = `${process.env.VUE_APP_LIVE_URL}/vod/${state.resourceId}/player`;
      return state.contentForm.type === 7 &&
        props.content.urlHls &&
        props.content.urlDash
        ? link
        : "";
    });

    const videoLink = computed(() => {
      return state.contentForm.type === 3 && state.contentForm.url
        ? state.contentForm.url
        : "";
    });

    onBeforeRouteLeave((to) => {
      state.nextRouteName = to.name;
      if (
        !state.pageOutApprove &&
        (state.isChange || state.deletedAttachments.length > 0) &&
        !(state.isEdit && state.showDeleteContentModal)
      ) {
        state.showPageOutWarning = true;
        return false;
      }
    });

    onMounted(() => {
      state.isEdit = true;
      if (!state.isEdit) {
        state.pageOutApprove = true;
      }
      state.resourceId = props.resourceId;
      //ncloud 썸네일 필드가 존재할 때, content featuredImage 가 empty.png 인경우  자동으로 썸네일 05번 이미지 업로드
      if (
        isNcloudThumbnails.value &&
        state.contentForm.featuredImage === proxy.$const.blankImage
      ) {
        let featuredImage = thumbnails.value[4];
        state.contentForm.featuredImage = featuredImage;
        state.prevFeaturedImage = featuredImage;
      }

      // Drm type이면 회원 전용 콘테츠로 업데이트.
      if (state.contentForm.type === 7) {
        state.contentForm.isUserOnly = true;
      }
    });

    const isEmptyStringValue = (value) => {
      return typeof value === "string" && value.length === 0;
    };

    const isStringValue = (value) => {
      return typeof value === "string" && value.length > 0;
    };

    const actions = {
      updateTitle: (value) => {
        state.contentForm.title = value;
      },
      updateContentDrmType: (value) => {
        state.contentForm.type = Number(value);
        state.contentForm.isUserOnly = true;
      },
      updateContentDigitalGoods: (value) => {
        state.contentForm.type = Number(value);
      },
      updateDescription: (value) => {
        state.contentForm.description = value;
      },
      updateText: (value) => {
        state.contentForm.text = value;
      },
      updatePublishedAt: (value) => {
        state.contentForm.publishedAt = value ? moment(value) : "";
      },
      updatePreviewImage: (previewImage) => {
        state.prevFeaturedImage = previewImage;
        state.contentForm.featuredImage = previewImage;
      },
      openAttachmentSelector: () => {
        attachmentFileInput.value.click();
      },
      addAttachment: (e) => {
        state.addAttachmentLoading = true;
        const files = e.target.files || e.dataTransfer.files;

        const formData = new FormData();
        formData.append("file", files[0]);
        // note 디지털 굿즈 전용 파라미터
        if (state.contentForm.type === 9) {
          formData.append("disk", "s3Content");
        }

        let payload = {
          resourceId: state.resourceId,
          file: formData,
        };

        e.target.value = "";

        store.dispatch("contents/uploadContentAttachment", payload).then(() => {
          swal.createCompleteToast();
          state.addAttachmentLoading = false;
        });
      },
      deleteAttachment: (attachmentResourceId) => {
        let payload = {
          resourceId: attachmentResourceId,
        };
        store.dispatch("contents/deleteContentAttachment", payload).then(() => {
          swal.deleteCompleteToast();
        });
      },
      onBtnSaveContent: () => {
        state.saveLoading = true;

        if (!isVideoDrmSetting.value.success) {
          state.showEssentialCheckModal = true;
          state.saveLoading = false;
          return;
        }

        if (state.deletedAttachments.length > 0) {
          state.contentForm.deletedAttachments = state.deletedAttachments;
        }
        if (state.attachments.length > 0) {
          state.contentForm.attachments = [];
          state.attachments.forEach((item) => {
            state.contentForm.attachments.push(item.resourceId);
          });
        }

        if (
          isEmptyStringValue(state.contentForm.url) &&
          isStringValue(state.contentForm.etag) &&
          isStringValue(state.contentForm.identifier)
        ) {
          state.contentForm.etag = "";
          state.contentForm.identifier = "";
        }

        const formData = cloneDeep(state.contentForm);
        delete formData.captions;

        if (state.isPrd) {
          delete formData.isIosMoweb;
          delete formData.isIosIframe;
          delete formData.isAosMoweb;
          delete formData.isAosIframe;
        }

        store
          .dispatch("contents/putContent", {
            resourceId: state.resourceId,
            data: formData,
          })
          .then(() => {
            state.pageOutApprove = true;
            swal.editCompleteToast();
            state.saveLoading = false;
          });
      },
      deleteContentFeaturedImage: () => {
        state.prevFeaturedImage = proxy.$const.blankImage;
        state.contentForm.featuredImage = "";
      },
      deleteContent: async () => {
        state.deleteLoading = true;

        let res = await store.dispatch(
          "contents/deleteContent",
          state.resourceId
        );

        if (res) {
          // 연결된 콘텐츠 삭제 시
          if (res.error) {
            swal.messageErrorAlert(res.error.message);
          }
          swal.messageErrorAlert(`티켓에 연결된 콘텐츠는 삭제가 불가능합니다.`);
          state.deleteLoading = false;
        } else {
          // 콘텐츠 삭제
          swal.deleteCompleteToast().then(() => {
            state.deleteLoading = false;
            emit("cancelContent");
          });
        }
      },
      pageOut: () => {
        // note 멀티파트 업로드용 데이터 정리 필요.
        state.triggerRouteLeave = true;
        state.pageOutApprove = true;
        state.showPageOutWarning = false;
        router.push({ name: state.nextRouteName });
      },
      pageOutCancel: () => {
        state.nextRouteName = null;
        state.showPageOutWarning = false;
      },
      closeEssentialCheckModal: () => {
        state.showEssentialCheckModal = false;
      },
      cancelContent: () => {
        emit("cancelContent");
      },
      showDeleteContentModal: () => {
        state.showDeleteContentModal = true;
      },
      closeDeleteContentModal: () => {
        state.showDeleteContentModal = false;
      },
      selectThumbnail: (imageUrl) => {
        state.prevFeaturedImage = imageUrl;
        state.contentForm.featuredImage = imageUrl;
      },
      openContentSettingModal: (type) => {
        state.uploadedType = type;
        state.showContentSettingModal = true;
      },
      closeContentSettingModal: () => {
        state.uploadedType = "";
        state.showContentSettingModal = false;
      },
      updateContentForm: (data) => {
        state.showContentSettingModal = false;

        // Todo 수정하기.
        delete data.isPaid;
        delete data.urlErrorMessage;

        state.contentForm = { ...state.contentForm, ...data };
        state.prevFeaturedImage = data.featuredImage ? data.featuredImage : "";
      },
    };

    return {
      state,
      actions,
      channelList,
      moment,
      attachmentFileInput,
      liveLink,
      videoLink,
      thumbnails,
      isNcloudThumbnails,
      isVideoDrmSetting,
      viewingMode,
      acceptFiles,
    };
  },
};
</script>

<style scoped>
.form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.attachment-add {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ecebf1;
  padding-top: 16px;
  margin-top: 16px;
}

.attachment-add h5 {
  margin-bottom: 0;
}

.attachment-add .attachment-btn {
  width: 48px;
}

.sub-form ul li {
  margin-bottom: 20px;
}

.sub-form ul li:last-child {
  margin-bottom: 0;
}

.sub-form {
  height: fit-content;
}

.description-area {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-wrapper .sub-form .thumbnail label {
  display: block;
  margin-bottom: 8px;
}

.form-wrapper .sub-form .thumbnail ul {
  display: flex;
  width: 100%;
  border-radius: 8px;
  overflow-x: scroll;
}

.form-wrapper .sub-form .thumbnail ul li {
  height: fit-content;
  margin-bottom: 0;
  position: relative;
}

.form-wrapper .sub-form .thumbnail ul li .btn-wrapper {
  z-index: 1;
  opacity: 0;
  transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-wrapper .sub-form .thumbnail ul li .btn-wrapper button {
  width: fit-content;
}

.form-wrapper .sub-form .thumbnail ul li:first-child {
  border-left: none;
}

.form-wrapper .sub-form .thumbnail ul li:last-child {
  border-right: none;
}

.thumbnail ul li .overlay {
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.4);
}

.thumbnail ul li:hover .overlay {
  opacity: 1;
}
.form-wrapper .sub-form .thumbnail ul li:hover .btn-wrapper {
  opacity: 1;
}

.form-wrapper .sub-form .thumbnail ul li img {
  display: block;
  object-fit: cover;
  width: auto;
  height: 200px;
}

.form-wrapper .sub-form .upload-btns {
  margin-top: 24px;
}

.form-wrapper .sub-form .upload-btns ul li {
  margin-bottom: 0;
}

.form-wrapper .sub-form .viewing-btns {
  margin-top: 24px;
}

@media (min-width: 768px) {
  .form-wrapper {
    padding: 24px;
    gap: 24px;
  }
}

@media (min-width: 1024px) {
  .form-wrapper {
    flex-direction: row;
    padding: 0;
  }

  .form-wrapper .main-form {
    width: 70%;
  }

  .form-wrapper .sub-form {
    width: 30%;
  }
}

.input-radio-area,
.embed-player-area,
.description-area,
.input-area {
  margin-bottom: 20px;
}

.content-type-area label {
  display: block;
  margin-bottom: 8px;
}

.input-radio-area {
  display: flex;
  gap: 10px;
}

.subtitle-file-list {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.content-preview-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.content-preview-section h3 {
  margin-bottom: 0;
}
</style>
